import * as React from "react";
import Link from 'components/link';

const FeaturedCardGrid = (props) => {

	let { title, repeater, fcImage, fcTitle, fcBody, fcLink, fcLinkText, introTitle, introBody } = props;

	
	return (
		<section className="featuredCardGrid">
			<div className="grid-container featuredCardGrid-container">
				<div className="grid-x grid-padding-x align-center">
					<div className="cell medium-11 text-left featuredCardGrid-intro">
						<h2 className="featuredCardGrid-title">{introTitle}</h2>
						<p className="featuredCardGrid-text">{introBody}</p>
					</div>
					<div className="cell medium-11 text-left" >
						<div className="grid-x featuredCardGrid-columns">
							<div className="cell medium-4 left-column">
								<h3>{fcTitle}</h3>
								<div className="featuredContent-card">
									<div className="featuredContent-card-image" style={{ backgroundImage: `url(${fcImage})` }}></div>
									<p>{fcBody}</p>
									<Link className="button hide-for-small-only" to={fcLink}>{fcLinkText}</Link>
									<Link className="button expanded show-for-small-only" to={fcLink}>{fcLinkText}</Link>
								</div>
							</div>
							<div className="cell medium-8 right-column">
								<h3>{title}</h3>
								<div className="grid-y">
									{repeater.map((thing, index) => (
										<div className="cell small-6" key={`featuredThing_${index}`}>
											<div className="featuredThing-card">
												<div className="featuredThing-card-image" style={{ backgroundImage: `url(${thing.image})` }}></div>
												<div className="featuredThing-card-content">
													<p>{thing.body}</p>
													<Link className="button hide-for-small-only" to={thing.link}>{thing.linkText}</Link>
													<Link className="button expanded show-for-small-only" to={thing.link}>{thing.linkText}</Link>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

	)
}

export default FeaturedCardGrid;