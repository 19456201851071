import { graphql } from 'gatsby';
import * as React from "react";

import Layout from 'components/templates/Layout';
import Crowdriff from "components/templates/global/Crowdriff";
import { FeaturedContentSlider } from "components/templates/global/FeaturedContentSlider";
import LatestNews from "components/templates/global/LatestNews";
import { default as Neighborhoods } from "components/templates/global/Neighborhoods";
import FeaturedCardGrid from "components/templates/home/FeaturedCardGrid";
import DataProvider from 'providers/data';

const IndexPage = props => {
	// this is the homepage
	// each component is isolated and does it's job.

	let posts = []
	const { edges } = props.data.allContentPage;
	const nodes = edges.map(({ node }) => node);
	let page = nodes[0];
	if ((props.providerResponse.data.length > 0 || !!page !== false) && props.providerStatusText === 'LOADED') {
		page = props.providerResponse.data[0];
	}

	if (page.contentRepeater && page.contentRepeater.length > 0) {
		page.contentRepeater = page.contentRepeater.map((content) => {
			content['description'] = content.body
			return content;
		})
	}

	if (page.contentRepeater2 && page.contentRepeater2.length > 0) {
		page.contentRepeater2 = page.contentRepeater2.map((content) => {
			content['description'] = content.body
			return content;
		})
	}

	if (page?.Post && page.Post.length > 0) {
		posts = page.Post.map(({ object }) => object).slice(0, 3);
	}
	
	return (
		<>
			<Layout headerClass="homepage">
				<FeaturedCardGrid title={page.thingsTitle} repeater={page.thingsHomeRepeater} fcImage={page.fcImage} fcTitle={page.fcTitle} fcBody={page.fcBody} fcLink={page.fcLink} fcLinkText={page.fcLinkText} introTitle={page.introTitle} introBody={page.introBody}/>
				<div style={{ marginTop: "85px" }}></div>
				<Neighborhoods />
				<div className='show-for-medium' style={{ marginTop: "163px" }}></div>
				<FeaturedContentSlider slides={page.contentRepeater} schema={{ side: "left", color: "normal", imageSize: "large", dotsSide: "center" }} />
				<FeaturedContentSlider slides={page.contentRepeater2} schema={{ side: "right", color: "alternate", imageSize: "small" }} />
				<div style={{ paddingBottom: "5rem" }} />
				<LatestNews posts={posts} />
				{page?.crowdriff && <Crowdriff hash={page?.crowdriff} />}
			</Layout>
		</>
	)
}

const getData = (props) => <DataProvider liveRefresh={props.params.liveRefresh} type="ContentPage" apiParams={{ uri: "/", _join: "Post" }}><IndexPage {...props} /></DataProvider>
export default getData;

export { Head } from 'components/templates/Head';

export const query = graphql`
query Homepage {
	allContentPage(filter: {uri: {eq: "/"}}) {
	  edges {
		node {
          
		  introTitle
		  introBody

		  fcImage
          fcTitle
          fcBody
          fcLink
          fcLinkText

		  thingsTitle
		  thingsHomeRepeater

		  contentRepeater
		  contentRepeater2
		  contentRepeater3

		  crowdriff

		  Post {
			object {
				id
				permalink
				title
				photo
				body
				uri
				showDate
				intro
			}
		  }

		  meta
		}
	  }
	}
  }
`