import * as React from "react"
import Link from 'components/link';
import Image from "components/Image";

import DataProvider from 'providers/data';
import { graphql, StaticQuery } from 'gatsby';


const Neighborhoods = props => {

	const { disableLink } = props;

	return (
		<StaticQuery
			query={graphql`
              query NeighborhoodQuery {
                allBeachNeighborhood(
                  sort: {order: ASC, fields: sort}
                  filter: {approved: {eq: true}, deleted: {eq: false}, archived: {eq: false}, featuredIcon: {ne: null}}
				  limit: 3
                ) {
                  edges {
                    node {
                      name
                      featuredIcon
                      featuredDescription
                      permalink
                    }
                  }
                }
              }
              
            `}
			render={(data) => {
				const { edges } = data.allBeachNeighborhood;
				let neighborhoods = edges.map(({ node }) => node);
				if ((props.providerResponse.data.length > 0 || !!neighborhoods !== false) && props.providerStatusText === "LOADED") {
					neighborhoods = props.providerResponse.data;
				}

				if (neighborhoods.length === 0) return null;

				return (
					<section className="component-container">
						<div className="top-container">
							<div className="grid-container">
								<div className="grid-x grid-padding-x align-center">
									<div className="cell small-10 medium-6 text-center small-text-center">
										<div className="">
											<h1 className="neighborhood-title">Neighborhoods</h1>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="middle-container">
							<div className="grid-container">
								<div className="grid-x grid-padding-x align-center">
									<div className="cell small-6 medium-2 text-center">
										<div className="">
											<hr className="hr-divider" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="bottom-container">
							<div className="grid-container">
								<div className="grid-x grid-padding-x align-spaced">
									{neighborhoods.map((neighborhood, index) => {
										return (
											<div key={`${neighborhood.name}-${index}`} className="cell small-10 medium-4 text-center" >
												<div className="neighboor-card flex-container flex-dir-column align-items">
													{!!disableLink ?
														(
															<div className="component-container">
																<div className="bitmap">
																	<Image src={`${neighborhood.featuredIcon}`} alt={`${neighborhood.name} Neighborhood`} />
																</div>
																<h2 className="bitmap-title">{neighborhood.name}</h2>
																<p className="bitmap-text">{neighborhood.featuredDescription}</p>
															</div>
														)
														:
														(
															<Link to={`/explore/${neighborhood.permalink}/`} className="component-container">
																<div className="bitmap">
																	<Image src={`${neighborhood.featuredIcon}`} alt={`${neighborhood.name} Neighborhood`} />
																</div>
																<h2 className="bitmap-title">{neighborhood.name}</h2>
																<p className="bitmap-text">{neighborhood.featuredDescription}</p>
															</Link>
														)}
												</div>
											</div>
										)
									})}
								</div>
							</div>
						</div>
					</section>
				)
			}}
		/>)
}

// export default Neighborhoods;
const getData = (props) => <DataProvider liveRefresh={true} type="BeachNeighborhood" apiParams={{ approved: true, archived: false, deleted: false, _limit: 3, _sort: "sort", _order: "asc", "!featuredIcon": "" }}><Neighborhoods {...props} /></DataProvider>
export default getData;